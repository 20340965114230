.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dokumente-modal {
  overflow: visible !important;
  padding: 0 2rem;
}

.dokumente-modal-overlay {
  overflow: scroll;
}

.without-chart-content {
  background-color: #f3f3f3;
  margin-top: 6vh;
  border-radius: 2vh;
  word-break: break-word;
  padding: 5vh 2vh 0vh 2vh;
  height: 15vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 600;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-block: 0;
  padding-inline-start: 20px;
  line-height: 2.4rem;
  font-weight: 300;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  margin-left: 0;
}