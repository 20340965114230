@import "../../styles/utils";

.login-page {
    position: relative;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    &.default-background {
        background: url('../../assets/images/gradient-backgroung.jpg') no-repeat;
        background-size: cover;
    }

    background-size: cover;
}

.login-content {
    position: relative;
    width: 41rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        display: flex;
        flex-direction: column;
        width: 100%;
        //height: calc(100% - 4.8rem - 6.4rem);
        height: calc(100vh - 20rem);
        margin-top: 6.4rem;
    }
}

.login-button-text {
    @include tablet {
        align-self: baseline;
    }
}
