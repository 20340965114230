@import "../../styles/utils";

.profile-page-content {
    display: grid;
    grid-gap: 4.8rem;
    grid-template-columns: repeat(3, 1fr);
    //grid-auto-rows: 20rem;

    grid-template-areas:
        "a a b"
        "c c ."
        "d d .";

    @include tablet {
        display: unset;
    }
}

.card-1 {
    grid-area: a;
    @include tablet {
        margin-bottom: 3rem;
    }
}

.card-2 {
    grid-area: b;
    @include tablet {
        margin-bottom: 3rem;
    }
}

.card-3 {
    grid-area: c;
    @include tablet {
        margin-bottom: 3rem;
    }
}

.card-4 {
    grid-area: d;
    @include tablet {
        position: relative;
        padding-bottom: 6rem;
    }
}

.personal-details {
    :last-child {
        :first-child {
            margin-bottom: 0;
        }
    }
}

.profile-civil-status-button {
    margin-left: 3.4rem;
    @media (max-width: 768px) {
        margin-left: 1.6rem;
    }
}

.list-group-top {
    padding: 4.8rem 4.8rem 3.2rem 4.8rem;
    @include tablet {
        padding: 2.8rem 2.8rem 3.2rem 2.8rem;
    }
}

.list-group-row {
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tablet {
            flex-wrap: wrap;
        }
        .left {
            color: $content;
            font-family: Inter;
            font-size: 1.6rem;
            font-weight: 300;
            @include tablet {
                font-size: 1.4rem;
                color: $grey;
                font-weight: 500;
            }
        }
    }

    font-size: 1.6rem;
    padding: 3.2rem 3.2rem 3.2rem 4.8rem;
    @include tablet {
        padding: 1.2rem 2.2rem 2.2rem 2.8rem;
    }

    .right {
        display: flex;
        align-items: center;
        .button {
            @include tablet {
                margin-top: 2rem;
                margin-left: auto;
            }
        }
        .mobile {
            @include tablet {
                margin: 0 !important;
                width: 100%;
            }
        }
        @include tablet {
            flex-direction: column;
            width: 100%;
        }
    }
}
.profile-address-edit-button {
    @include tablet {
        position: absolute;
        right: 2.2rem;
        bottom: 2.2rem;
    }
}

.profile-modal {
    overflow: visible;
    height: 100vh !important;
}

hr {
    margin: 0;
}


.profile-modal-text {
    max-width: 433px;
    color: $content;
    font-family: Inter;
    font-size: 1.6rem;
    font-weight: 300;
    margin-bottom: 3rem;
}

.mobile-modal-header {
    display: none;
    @include tablet {
        display: block;
        margin-bottom: 12rem;
    }
}

.mobile-modal-title {
    @include tablet {
        margin-top: 4.5rem !important;
    }
}

.profile-modal-wrapper {
    margin-bottom: 21rem;
    @include tablet {
        margin: 0 2.3rem;
    }
}
.profile-modal-content {
    @include tablet {
        padding: 2.4rem !important;
    }
}

.profile-mobile-modal-text {
    @include tablet {
        color: $content;
        font-family: Inter;
        font-weight: 300;
    }
}
